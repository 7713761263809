import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardHeader, Collapse, Modal } from 'reactstrap';
import CIcon from '@coreui/icons-react';
import { cilHistory } from '@coreui/icons';
import EvaluationAdminModal from './EvaluationAdminModal';
import Avviso from '../../common/Avviso';
import RichiestaConferma from '../../common/RichiestaConferma';
import moment from 'moment';
import ModalSpinner from '../../common/ModalSpinner';
import SingleReport from './SingleReport';
import ModaleAllegati from '../../common/ModaleAllegati';
import axios from 'axios';
import { apiconfig } from '../../shared/apiconfig';
import ModaleEventoDocente from '../Planning/ModaleEventoDocente';
import { downloadAllegatoPlanning, downloadAllegatoPlanningStud } from '../../services/commons';

export default function EvaluationAdminNew({
    notifications,
    historyData,
    id,
    setAvviso,
    docente,
    studenti,
    materie,
    getData,
    isAdmin,
    data,
    userData,
    docenti,
    is_docente
}) {
    // region variables
    const [history, setHistory] = useState(!historyData);
    const [modalData, setModalData] = useState({ attivo: false, data: null });
    const [spinner, setSpinner] = useState(false);
    const [data2, setData2] = useState([]);
    const [avvisoData, setAvvisoData] = useState({ attivo: false });
    const [avvisoFeedData, setAvvisoFeedData] = useState({ attivo: false });
    const [modaleEventoData, setModaleEventoData] = useState({});
    const [reset, setReset] = useState(false);
    const [modaleAllegatoData, setModaleAllegatoData] = useState({
        idAllegatoInAttesa2: null,
        nomeFileAllegatoNuovo2: null,
        isNotLesson: false,
        modaleAllegatiAttiva: false,
        eventi: [],
        idEventoSelezionato: 0,
        idTipoAllegatoNuovo: 0,
        idMateriaAllegatoNuovo: 0,
        selectedStud: {},
        materie,
        studenti,
        evento: {},
        idClasseAllegatoNuovo: 0,
        idScuolaAllegatoNuovo: 0,
        listaAllegatiRicerca: [],
        allegatiSelezionati: []
    });
    useEffect(() => {
        if (docente) {
            setData2(notifications);
            return;
        }
        let temp = [];
        notifications.map(e => {
            let index = temp.findIndex(
                el =>
                    el.id_planning === e.id_planning &&
                    e.id_docente === el.id_docente &&
                    el.id_studente === e.id_studente
            );
            let planning = e.planning[0] || e.planning;
            if (index < 0) {
                temp.push({
                    ...e,
                    materie: materie.filter(el => el.id === e.id_materia),
                    data: [e],
                    allegati: planning.allegati
                });
            } else {
                temp[index].materie.push(materie.find(el => el.id === e.id_materia));
                temp[index].data.push(e);
            }
        });
        setData2(temp);
    }, [reset, notifications]);
    // endregion
    const resetMap = () => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idAllegatoInAttesa2: null,
            nomeFileAllegatoNuovo2: null
        }));
    };

    const handleChangeTipoAllegato = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idTipoAllegatoNuovo: arg.target.value
        }));
    };
    const handleChangeMateriaAllegato = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idMateriaAllegatoNuovo: arg.target.value
        }));
    };
    const handleChangeClasseAllegato = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idClasseAllegatoNuovo: arg.target.value
        }));
    };
    const handleChangeScuolaAllegato = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idScuolaAllegatoNuovo: arg.target.value
        }));
    };
    const suCompletamentoUploadModale = idFile => {
        if (idFile)
            setModaleAllegatoData(prevState => ({
                ...prevState,
                idAllegatoInAttesa: idFile?.uploadResponse.data
            }));
    };
    const suCompletamentoUploadModaleMap = idFile => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            idAllegatoInAttesa2: idFile.uploadResponse.data,
            nomeFileAllegatoNuovo2: idFile.file.name
        }));
    };
    const ConfermaAllegato = () => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            modaleAllegatiAttiva: false
        }));
        if (!modaleAllegatoData?.selAllegatoEsistente) {
            if (
                !modaleAllegatoData.idAllegatoInAttesa &&
                (!modaleAllegatoData.idAllegatoModificato || modaleAllegatoData.idAllegatoModificato == 0)
            ) {
                return;
            }
            axios
                .post(apiconfig().baseURL + 'confermaAllegato', {
                    idFile: modaleAllegatoData.idAllegatoInAttesa,
                    idAllegatoModificato: modaleAllegatoData.idAllegatoModificato,
                    idFileMappa: modaleAllegatoData.idAllegatoInAttesa2,
                    id_tipo: modaleAllegatoData.idTipoAllegatoNuovo,
                    id_scuola: modaleAllegatoData.idScuolaAllegatoNuovo,
                    esistente: 0,
                    classe: modaleAllegatoData.idClasseAllegatoNuovo,
                    id_materia: modaleAllegatoData.idMateriaAllegatoNuovo,
                    descrizione: modaleAllegatoData.descrAllegatoNuovo,
                    idPlanning: modaleAllegatoData.id_planning,
                    idStudente: modaleAllegatoData.idStudenteModaleAllegati
                })
                .then(() => {
                    setModaleAllegatoData(prevState => ({
                        ...prevState,
                        idAllegatoInAttesa: null
                    }));
                    getData();
                    setReset(!reset);
                });
        } else {
            axios
                .post(apiconfig().baseURL + 'confermaAllegatoEsistente', {
                    idFile: modaleAllegatoData.allegatiSelezionati,
                    idPlanning: modaleAllegatoData.id_planning,
                    idStudente: modaleAllegatoData.idStudenteModaleAllegati
                })
                .then(() => {
                    // window.location.reload();
                    getData();
                    setReset(!reset);
                });
        }
    };

    const selAllegatoEsistente = () => {};
    const handleChangeAllegatoEsistente = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            selAllegatoEsistente: arg.target.checked
        }));
    };
    const handleChangeSelAllegatoEsistente = (allegato, arg) => {
        let listaAllegati = modaleAllegatoData.allegatiSelezionati;
        if (arg.target.checked) {
            if (!listaAllegati.find(el => el.id == allegato.id)) {
                listaAllegati.push(allegato);
            }
        } else {
            const idx = listaAllegati.findIndex(el => el.id == allegato.id);
            if (idx != -1) {
                listaAllegati.splice(idx, 1);
            }
        }
        setModaleAllegatoData(prevState => ({
            ...prevState,
            allegatiSelezionati: listaAllegati
        }));
    };
    const handleChangeDescrAllegato = arg => {
        setModaleAllegatoData(prevState => ({
            ...prevState,
            descrAllegatoNuovo: arg.target.value
        }));
    };
    return (
        <Card style={{ overflow: 'auto' }}>
            <Avviso
                {...avvisoFeedData}
                toggle={() => {
                    setAvvisoFeedData({ attivo: false });
                }}
            />
            {!isAdmin && (
                <ModaleEventoDocente
                    handleChangeScuolaAllegato={console.info}
                    // resetMap={this.resetMap}
                    attivo={modaleEventoData.attivo}
                    evento={modaleEventoData.evento || {}}
                    forceDocente={modaleEventoData.docente}
                    readOnly
                    handleChangeClasseAllegato={console.info}
                    toggle={() => {
                        setModaleEventoData(false);
                    }}
                    handleChangeAllegatoEsistente={console.info}
                    scaricaAllegatoPlanning={downloadAllegatoPlanning}
                    scaricaAllegatoPlanningStud={downloadAllegatoPlanningStud}
                    idDocenteLezioneVisualizzata={modaleEventoData.idDocente}
                    idEventoSelezionato={modaleEventoData.evento?.id}
                />
            )}
            {!docente ? (
                <>
                    <ModaleAllegati
                        path={'report'}
                        resetMap={resetMap}
                        isNotLesson={modaleAllegatoData.isNotLesson}
                        attivo={modaleAllegatoData.modaleAllegatiAttiva}
                        eventi={modaleAllegatoData.eventi}
                        idEvento={modaleAllegatoData.idEventoSelezionato}
                        //close
                        toggle={() => {
                            setModaleAllegatoData(prevState => ({ ...prevState, modaleAllegatiAttiva: false }));
                        }}
                        idTipoAllegatoNuovo={modaleAllegatoData.idTipoAllegatoNuovo}
                        handleChangeTipoAllegato={handleChangeTipoAllegato}
                        idMateriaAllegatoNuovo={modaleAllegatoData.idMateriaAllegatoNuovo}
                        selectedStud={modaleAllegatoData.selectedStud}
                        materie={modaleAllegatoData.evento.materie}
                        studenti={modaleAllegatoData.evento.studenti}
                        handleChangeMateriaAllegato={handleChangeMateriaAllegato}
                        classeAllegatoNuovo={modaleAllegatoData.idClasseAllegatoNuovo}
                        handleChangeClasseAllegato={handleChangeClasseAllegato}
                        idScuolaAllegatoNuovo={modaleAllegatoData.idScuolaAllegatoNuovo}
                        handleChangeScuolaAllegato={handleChangeScuolaAllegato}
                        descrAllegatoNuovo={modaleAllegatoData.descrAllegatoNuovo}
                        handleChangeDescrAllegato={handleChangeDescrAllegato}
                        fileUploaderParams={{ id_studente: modaleAllegatoData.selectedStud?.id }}
                        fileUploaderPath="allegatoTmp"
                        suCompletamento={suCompletamentoUploadModale}
                        suCompletamentoMap={suCompletamentoUploadModaleMap}
                        conferma={ConfermaAllegato}
                        selAllegatoEsistente={modaleAllegatoData.selAllegatoEsistente}
                        mostraSelAllegatoEsistente={true}
                        handleChangeAllegatoEsistente={handleChangeAllegatoEsistente.bind(this)}
                        handleChangeSelAllegatoEsistente={handleChangeSelAllegatoEsistente}
                        tipoAllegato={'allegatoDocente'}
                        listaAllegati={modaleAllegatoData.listaAllegatiRicerca}
                        allegatiSelezionati={modaleAllegatoData.allegatiSelezionati}
                    />
                    <EvaluationAdminModal
                        attivo={modalData.attivo}
                        setAvviso={setAvviso}
                        data={modalData.data}
                        notification={modalData.notification}
                        getData={() => {
                            getData();
                            setReset(!reset);
                        }}
                        close={() => {
                            setModalData({ ...modalData, attivo: false });
                        }}
                    />
                    <RichiestaConferma
                        attivo={avvisoData?.attivo}
                        toggle={data => {
                            if (data === 2) {
                                setAvvisoData({ attivo: false });
                            } else if (avvisoData?.callback) {
                                avvisoData.callback();
                                setAvvisoData({ attivo: false });
                            }
                        }}
                        titolo={avvisoData?.titolo}
                        messaggio={avvisoData?.messaggio}
                    />
                </>
            ) : null}
            <ModalSpinner attivo={spinner} />
            <CardHeader
                style={{ fontSize: 18 }}
                className={'cursor-pointer d-flex justify-content-between align-items-center hover-darkness'}
                onClick={() => setHistory(!history)}
            >
                {' '}
                {historyData ? (
                    <div>
                        <CIcon icon={cilHistory} style={{ color: '#0af', fontWeight: 'bold' }} size={'lg'} /> Storico{' '}
                        <Badge color={'danger'}>{notifications.length}</Badge>
                    </div>
                ) : !is_docente ? (
                    <div>
                        <CIcon icon={cilHistory} style={{ color: '#0af', fontWeight: 'bold' }} size={'lg'} /> Richieste
                        da evadere <Badge color={'danger'}>{data2.length}</Badge>
                    </div>
                ) : (
                    <div>
                        <Badge color={'danger'}>{notifications.length}</Badge> &nbsp;&nbsp;Messaggi
                    </div>
                )}
                {historyData && (
                    <div
                        className={'pt-0 px-2 ml-auto cursor-pointer bg-light text-primary icon-container'}
                        style={{ marginRight: 20, borderRadius: 8, boxShadow: '1px 1px 3px #0000003d' }}
                    >
                        <i
                            className={'fa ' + (history ? 'fa-minus' : 'fa-plus')}
                            style={{ fontSize: 16, marginTop: 4 }}
                        />
                    </div>
                )}
            </CardHeader>
            <Collapse isOpen={history}>
                {/*<SingleEvaluationAdmin*/}
                {/*    newRequest*/}
                {/*    getData={getData}*/}
                {/*    setAvviso={setAvviso}*/}
                {/*    setModalData={setModalData}*/}
                {/*    isAdmin*/}
                {/*    notification={{ status: MAPPED_STATUS.NEW ,dataRichiesta: new Date(),risposta:'Sono uina risposta stab',testo:New_notification_stab }}*/}
                {/*    status={MAPPED_STATUS[e.status]}*/}
                {/*    isLast={false}*/}
                {/*/>*/}
                {(is_docente && !isAdmin ? [...notifications] : [...data2] || [])
                    .sort((a, b) => {
                        let planningA = a.planning[0] || a.planning;
                        let planningB = b.planning[0] || b.planning;
                        return moment(planningA?.data_planning + ' ' + planningA?.start)?.format('YYYY-MM-DD HH:mm') >=
                            moment(planningB?.data_planning + ' ' + planningB?.start)?.format('YYYY-MM-DD HH:mm')
                            ? -1
                            : 1;
                    })
                    .map((e, i) => (
                        <>
                            {!docente ? (
                                <SingleReport
                                    readOnly={is_docente}
                                    evaluation={e}
                                    setRequest={setAvvisoData}
                                    setModaleAllegati={data => {
                                        setModaleAllegatoData(prevState => ({
                                            ...prevState,
                                            ...data
                                        }));
                                    }}
                                    getData={() => {
                                        getData();
                                        setReset(!reset);
                                    }}
                                    setAvviso={setAvvisoFeedData}
                                    studente={Object.values(data.studenti || {})?.find(el => el.id === e.id_studente)}
                                    setModalEvaluation={setModalData}
                                    planningAdmin={e.planning[0] || e.planning}
                                    evento={{
                                        ...(e.planning[0] || e.planning || {}),
                                        allegati: [],
                                        docenti: data.docenti ? Object.values(data.docenti || {}) : [userData],
                                        materie: e.materie,

                                        studenti: Object.values(data.studenti || {})
                                    }}
                                    idDocente={is_docente ? id : e.id_docente}
                                />
                            ) : (
                                <>
                                    {i === 0 && (
                                        <div
                                            className={'d-flex align-items-center child-flex-1 child-d-flex-center p-2'}
                                            style={{
                                                borderBottom: '1px solid #e4e4e4',
                                                borderTop: '1px solid #e4e4e4',
                                                backgroundColor: 'rgba(0, 0, 21, 0.03)'
                                            }}
                                        >
                                            <b>Messaggio</b>
                                            <b>Studente</b>
                                            <b>Planning</b>
                                        </div>
                                    )}
                                    <div className={'d-flex align-items-center child-flex-1 child-d-flex-center p-2'}>
                                        <span>
                                            <span>{e.messaggio}</span>
                                        </span>
                                        <span>
                                            {e.planning?.studenti && (
                                                <a
                                                
                                                    href={
                                                        e.planning?.studenti[0]
                                                            ? '#/datiutente/' + e.planning?.studenti[0]?.id
                                                            : '#/valutazioniDoc'
                                                    }
                                                    target={e.planning?.studenti[0] ? '_blank' : '_self'}
                                                >
                                                    {(e.planning?.studenti[0]?.cognome || 'Studente') +
                                                        ' ' +
                                                       ( e.planning?.studenti[0]?.nome || "Elminato")}
                                                </a>
                                            )}
                                        </span>
                                        <span>
                                            <a
                                                onClick={() => {
                                                    let currentEvent = e.planning[0] || e.planning || {};
                                                    let params = { id_planning: currentEvent.id };
                                                    axios
                                                        .get(apiconfig().baseURL + 'dettagliplanningdoc', { params })
                                                        .then(response => {
                                                            const insertedMaterie = [];
                                                            currentEvent.materie = [];
                                                            currentEvent.dettagli = response.data.map(x => {
                                                                if (!insertedMaterie.includes(x.id_materia)) {
                                                                    currentEvent.materie = [
                                                                        ...(currentEvent.materie || []),
                                                                        materie?.find(e => e.id === x.id_materia)
                                                                    ];
                                                                    insertedMaterie.push(x.id_materia);
                                                                }
                                                                return {
                                                                    id_docente: x.id_docente,
                                                                    id_studente: x.id_studente,
                                                                    id_materia: x.id_materia,
                                                                    annotazioni: x.annotazioni,
                                                                    attivo: x.attivo,
                                                                    valutazioni: x.valutazioni,
                                                                    argomento: x.argomento,
                                                                    modificato: false
                                                                };
                                                            });

                                                            currentEvent.attivitaSvolteModificate = false;
                                                            setModaleEventoData({
                                                                attivo: true,
                                                                evento: currentEvent,
                                                                docente: { ...userData, presenza: 1 }
                                                            });
                                                        })
                                                        .catch(error => {});
                                                }}
                                            >
                                                <Button style={{ maxWidth: 150 }}>Visualizza report</Button>
                                            </a>
                                        </span>
                                    </div>
                                </>
                            )}
                        </>
                    ))}
            </Collapse>
        </Card>
    );
}
